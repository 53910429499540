import { CloseIcon, FullLogo, MenuIcon, LoginIcon } from '@c/icons';
import Button from '@ui/Button';
import { searchClient } from '@util/getAlgolia';
import { isLargeDesktop } from '@util/index';
import { useAuth } from 'context/AuthContext';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { InstantSearch } from 'react-instantsearch-hooks-web';
import ChatButton from './icons/buttons/ChatButton';
import ShoppingCartButton from './icons/buttons/ShoppingCartButton';
import CategoryBar from './navigation/CategoryBar';

const BaseModal = dynamic(() => import('@c/modals/BaseModal'), { ssr: false });
const SearchBox = dynamic(() => import('@ui/SearchBox'), { ssr: false });
const UserMenu = dynamic(() => import('@c/menus/UserMenu'), { ssr: false });
const Input = dynamic(() => import('@ui/Input'), { ssr: false });

interface NavBarProps {
  showCloseButton: boolean;
  onActionButtonClicked?: () => void;
}
const Navbar = ({ showCloseButton, onActionButtonClicked }: NavBarProps) => {
  const { user, userDoc } = useAuth();
  const router = useRouter();

  const [searchboxFocused, setSearchboxFocused] = useState(false);
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  
  return (
    <InstantSearch searchClient={searchClient} indexName="products">
      <div className="sticky top-0 z-50 w-full shadow-lg">
        {/* Top bar */}
        <div className="border-b border-black top-0 z-40 flex h-[8rem] w-full items-center bg-brand-white px-12 lg:gap-[1.6rem]">
          {/* Left nav items */}
          {/* desktop */}
          <div className="hidden grow gap-[2.4rem] lg:flex">
            <Link
              href="/"
              title="Gear Focus, Your New And Use Camera Marketplace"
              className="flex items-center"
              aria-label="brand link to homepage"
            >
              <FullLogo variant="default" width={257} height={53} />
            </Link>

            <SearchBox
              white={true}
              onBlur={() => setSearchboxFocused(false)}
              onFocus={() => setSearchboxFocused(true)}
            />
          </div>

          {/* mobile */}
          <div className="flex w-full pl-[0.8rem] lg:hidden">
            <button
              type="button"
              aria-label="menu-trigger"
              onClick={onActionButtonClicked}
              className="mr-[1.6rem] text-brand-black lg:hidden lg:w-fit"
            >
              {showCloseButton ? <CloseIcon /> : <MenuIcon />}
            </button>
            <Link
              aria-label="link to home"
              href="/"
              className="flex items-center justify-start lg:w-1/3"
            >
              <FullLogo variant="default" width={150} height={32} />
            </Link>
          </div>
          {/* end left nav items */}

          {/* Right nav items */}
          {(!searchboxFocused || isLargeDesktop()) && (
            <div className="flex items-center gap-[0.4rem] font-medium text-brand-black lg:gap-[1.2rem]">
              {user ? (
                <div className="hidden gap-[0.8rem] lg:flex">
                  <div className="order-3">
                    <ShoppingCartButton displayCount={true} />
                  </div>
                  {/* wishlist 
                  <Link
                    href="/wishlist"
                    className="w-full min-w-[4.4rem] p-4 text-brand-black transition-colors hover:text-brand-primary"
                    aria-label="wishlist"
                  >
                    <HeartIcon />
                  </Link>*/}
                  <ChatButton isSupport={!!userDoc?.roles?.support} />
                  <div className="hidden items-center lg:flex">
                    <UserMenu />
                  </div>
                </div>
              ) : (
                <div className="flex items-center gap-[0.8rem] ml-[1.6rem]">
                  <ShoppingCartButton displayCount={true} />
                  <div className="gap-[0.8rem]">
                    <Link
                      href="/login"
                      className="flex flex-col items-center gap-1 text-brand-black hover:text-brand-primary"
                      aria-label="login and sign up"
                      title="Login / Sign up"
                    >
                      <div className="scale-[0.8]">
                        <LoginIcon />
                      </div>
                      <span className="text-[1rem] hidden md:flex">Sign Up / Login</span>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="hidden items-center justify-center lg:flex">
            <Button
              text="Sell your gear"
              title="Sell Your Used Camera Gear With Gear Focus"
              href={
                userDoc?.account_activated ? '/create-listing' : '/sellyourgear'
              }
              height="small"
            />
          </div>
        </div>

        {/* Bottom bar */}
        <div className="p-[1rem] lg:hidden bg-brand-white">
          <button className="w-full" onClick={() => setSearchModalOpen(true)}>
            <Input
              id="global-search-input"
              readonly
              placeholder="Search for high quality, pre-owned gear..."
              type="search"
              white onDark
              bordered searchIconRight
            />
          </button>
        </div>

        <CategoryBar />

        {/* Search Modal */}
        <BaseModal isOpen={searchModalOpen} hideCloseIcon isFullScreen>
          <div className="p-[1.6rem]">
            <SearchBox
              goBack={() => setSearchModalOpen(false)}
              searchIconRight
            />
          </div>
        </BaseModal>
      </div>
    </InstantSearch>
  );
};

export default Navbar;
