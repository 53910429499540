import { ChevronDownIcon } from '@c/icons';
import { cva, VariantProps } from 'class-variance-authority';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';

const AccordionStyles = cva('transition-all cursor-pointer', {
  variants: {
    hover: {
      default: 'hover:text-brand-secondary hover:bg-brand-secondary-light',
      text: 'hover:text-brand-primary',
      none: '',
    },
    intent: {
      default: 'p-[0.8rem] ',
      faq: 'p-0 pb-[1.6rem]',
      fullWidth: 'p-0 py-[0.8rem]',
    },
  },
  defaultVariants: {
    hover: 'default',
    intent: 'default',
  },
});

interface AccordionProps extends VariantProps<typeof AccordionStyles> {
  classes?: string;
  title: string | React.ReactElement;
  children: React.ReactNode;
  startOpen?: boolean;
  divider?: boolean;
  highlightHeaderOnOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  noBoldTitle?: boolean;
  hideArrow?: boolean;
  itemProp?: string;
}

const Accordion = ({
  classes,
  title,
  children,
  startOpen,
  divider,
  hover,
  intent,
  highlightHeaderOnOpen,
  onOpen,
  onClose,
  noBoldTitle,
  hideArrow,
  itemProp,
}: AccordionProps) => {
  const [open, setOpen] = useState(startOpen);
  const [shouldAnimate, setShouldAnimate] = useState(!startOpen);

  const getIconRotation = () => {
    return open ? 'rotate-180' : 'rotate-0';
  };

  const getHeaderColor = () => {
    return highlightHeaderOnOpen && open
      ? 'text-brand-primary'
      : 'text-brand-black';
  };

  // Logic to animate the accordion after first render. This is needed because we don't want to animate when startOpen is true.
  useEffect(() => {
    if (open) {
      setShouldAnimate(true);
    }
  }, [open]);

  function handleClick() {
    if (open) {
      onClose?.();
      setOpen(false);
    } else {
      onOpen?.();
      setOpen(true);
    }
  }

  return (
    <div className="w-full">
      <div
        onClick={handleClick}
        className={`flex w-full items-center justify-between rounded-[1rem]
        ${classes}
        ${AccordionStyles({ hover, intent })}
        ${getHeaderColor()}`}
      >
        <span className={`w-full ${noBoldTitle ? '' : 'font-semibold'}`} {...(itemProp && { itemProp })}>
          {title}
        </span>
        {!hideArrow && (
          <div className={`${getIconRotation()} ml-4 transition-all`}>
            <ChevronDownIcon />
          </div>
        )}
      </div>
      <AnimatePresence>
        {!!divider && !open && (
          <motion.hr initial={{ opacity: 0 }} animate={{ opacity: 1 }} />
        )}
        <motion.div
          key="content"
          // animate in from top to bottom by growing height
          initial={{ height: 0, overflow: 'hidden' }}
          animate={{
            height: open ? 'auto' : 0,
            transitionEnd: {
              overflow: open ? 'visible' : 'hidden',
            },
          }}
          // animate out from bottom to top by shrinking height
          exit={{ height: 0, overflow: 'hidden' }}
          transition={{ duration: shouldAnimate ? 0.25 : 0 }}
          className="w-full"
        >
          {children}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default Accordion;
