import { useShoppingCart } from 'context/ShoppingCartContext';
import { CartIcon } from '..';
import { useRouter } from 'next/router';
import Image from 'next/image';

const ShoppingCartButton = ({ displayCount }: { displayCount?: boolean }) => {
  const { getItemCount, setCartOpen } = useShoppingCart();
  const itemCount = displayCount ? getItemCount() : undefined;
  const handleClick = () => setCartOpen((prev) => !prev);
  const router = useRouter();

  return (
    <button
      onClick={handleClick}
      className="relative"
      aria-label="shopping cart"
    >
      <Image src="/cart.png" alt="Shopping Cart" height={40} width={40} />
      {!!itemCount && <span className="absolute top-3 left-7 text-[1.5rem] hidden sm:inline">&nbsp;{itemCount || ''}</span>}
    </button>
  );
};

export default ShoppingCartButton;
