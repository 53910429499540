import { useState, useEffect } from 'react';
import { getCategories, CategoryDocument } from '@util/firestore/categories'

import Link from 'next/link';
import NavMenu from './NavMenu';
import NavMenuSection from './NavMenuSection';

const CategoryBar = () => {
  const [categories, setCategories] = useState<CategoryDocument[]>([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const allCategories = await getCategories(2);
        const level1Categories = allCategories.filter(cat => cat.level === 1);
        level1Categories.forEach(l1Cat => {
          l1Cat.sub_categories = allCategories.filter(cat => cat.parents?.includes(l1Cat.id));
        });
        setCategories(level1Categories);
      } catch (error) {
        console.error('Failed to fetch categories:', error);
      }
    };

    fetchCategories();
  }, []);

  return (
    <div className="z-50 hidden h-[5rem] items-center justify-between gap-[1.6rem] border-b border-brand-lightest-gray bg-brand-white px-12 text-center font-medium text-black/[0.87] lg:flex [&>*]:text-[1.2rem]">      <div className="flex gap-[1.8rem] whitespace-nowrap [&>*]:text-left">        
        <Link className="text-[1.4rem] hover:text-brand-primary" href="/c/digital-cameras">
          Cameras
        </Link>
        <Link className="text-[1.4rem] hover:text-brand-primary" href="/c/photo-video-lenses">
          Lenses
        </Link>
        <Link className="text-[1.4rem] hover:text-brand-primary" href="/c/video-camcorders">
          Video
        </Link>
        <Link className="text-[1.4rem] hover:text-brand-primary" href="/c/vintage-camera-equipment">
          Vintage
        </Link>
        <Link className="text-[1.4rem] hover:text-brand-primary" href="/c/lighting-studio">
          Lighting
        </Link>
        <Link className="text-[1.4rem] hover:text-brand-primary" href="/c/drones-aerial-imaging">
          Drones
        </Link>
        <Link className="text-[1.4rem] hover:text-brand-primary" href="/c/computers-electronics">
          Computers
        </Link>
        <Link className="text-[1.4rem] hover:text-brand-primary" href="/c/photo-video-accessories">
          Accessories
        </Link>
      </div>
      <div className="flex gap-[1.4rem] whitespace-nowrap [&>*]:text-left">        
          <NavMenu label="Categories" href="/products">
            <div className="xl:columns-4 lg:columns-3 gap-[1.6rem] max-h-[80] ">
              {categories.map((category) => (
                <NavMenuSection label={category.name} href={`/c/${category.slug}`} key={category.id}>
                  {category.sub_categories?.map((subCat: CategoryDocument) => (
                    <Link className="hover:text-brand-secondary" href={`/c/${category.slug}/${subCat.slug}`} key={subCat.id}>
                      {subCat.name}
                    </Link>
                  ))}
                </NavMenuSection>
              ))}
            </div>
          </NavMenu>
          <Link className="text-[1.4rem] hover:text-brand-primary" href="/brands">
            Brands
          </Link>
          <NavMenu label="Start Selling" href="/become-a-seller" orientation='vertical'>
          <div className="flex flex-col w-full gap-[1.6rem] pb-[2rem]">
              <Link className="hover:text-brand-secondary" href="/sellyourgear">
                Sell Your Gear
              </Link>
              <Link
                className="hover:text-brand-secondary"
                href="/sellyourgear#fees"
              >
                Seller Fees
              </Link>
              <Link className="hover:text-brand-secondary" href="/about">
                Why Gear Focus?
              </Link>
              <Link
                className="hover:text-brand-secondary"
                href="/gearfocus-protection"
              >
                Gear Focus Protection
              </Link>
            </div>
          </NavMenu>
          <NavMenu label="Help" href="/help" orientation='vertical'>
            <div className="flex flex-col w-full gap-[1.6rem] pb-[2rem]">
              <Link className="hover:text-brand-secondary" href="/about">
                About Us
              </Link>
              <Link className="hover:text-brand-secondary" href="/contact-us">
                Contact
              </Link>
              <Link className="hover:text-brand-secondary" href="/faq">
                FAQ
              </Link>
              <Link
                className="hover:text-brand-secondary"
                href="/terms-and-conditions"
              >
                Terms & Conditions
              </Link>
            </div>
          </NavMenu>
          <Link className="text-[1.4rem] hover:text-brand-primary" href="/blog">
            Blog
          </Link>
          <Link className="text-[1.4rem] hover:text-brand-primary" href="/videos">
            Videos
          </Link>
      </div>
    </div>
  );
};

export default CategoryBar;
